import LazyLoad from "vanilla-lazyload";
import '../scss/global.scss';
import './polyfills';
import axios from 'axios';
import { tns } from "tiny-slider/src/tiny-slider"
import SimpleLightbox from 'simplelightbox';
import '../node_modules/simplelightbox/dist/simple-lightbox.min.css';

const lazyload = new LazyLoad(
{
    elements_selector: '.lazyload',
});

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', async (e) => {
    if (e.target.matches('.password-toggle')) {
      const target = e.target.dataset.target;
      const field = document.querySelector(target);
      field.type = field.type == 'password' ? 'text' : 'password';
      e.target.classList.toggle('input__icon--password-show');
    }

    if (e.target.matches('.delete_saved_order')) {
      e.preventDefault();

      const deleteOrder = confirm('Are you sure you want to delete this saved order?');

      if (deleteOrder) {
        const call = await axios.post(e.target.href);

        if (call.data.success) {
          e.target.parentNode.remove();
        }
      }
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  console.log(`Current page: ${prestashop.page.page_name}`);

  if (document.querySelector('#step_slider')) {
    var stepSlider = tns({
      container: '#step_slider',
      controlsContainer: '#step_slider_controls',
      nav: false,
      items: 2,
      gutter: 13,
      slideBy: 1,
      loop: false,
      responsive: {
        475: {
          items: 3
        },
        768: {
          items: 4,
          gutter: 30
        },
        992: {
          items: 5
        },
        1200: {
          items: 7
        },
      }
    });

    let steps = document.getElementById('step_slider').getElementsByClassName('step');
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].classList.contains('active')) {
        stepSlider.goTo(i);
        break;
      }
    }
  }
});

const mobMenu = document.querySelector('.header__megamenu__mobile');
const mobMenuTrigger = document.querySelector('#mobile_menu_trigger');
const search_widget = document.querySelector('#search_widget');
const burger = document.querySelector('#nav-icon-mobile');
let cartModal;
let overlay;

document.body.addEventListener('click', (e) => {
  const { target } = e;
  overlay = document.querySelector('.modal__overlay');
  cartModal = document.querySelector('#blockcart-modal');

  switch (true) {
    case target.matches('.continue'):
      overlay.remove();
      cartModal.remove();
      break;
    case target.matches('.modal__overlay'):
      overlay.remove();
      cartModal.remove();
      break;
    case target.matches('.top_banner__mobile__search_icon')  || target.matches('.top_banner__mobile__search_icon svg') || target.matches('.top_banner__mobile__search_icon svg circle') || target.matches('.top_banner__mobile__search_icon svg line'):
      mobMenu.classList.toggle('search_visible');
      mobMenu.classList.remove('visible');
      burger.classList.remove('open');
      mobMenuTrigger.classList.remove('active');
      break;
    case target.matches('.link_block__block_title') && window.innerWidth <= 991:
      target.classList.toggle('open-block');
      target.nextElementSibling.classList.toggle('block-visible');
      break;
    case target.matches('.searcher_icon') || target.matches('.searcher_icon svg') || target.matches('.searcher_icon svg circle') || target.matches('.searcher_icon svg line') :
      search_widget.classList.toggle('widget-visible');
      break;
    case target.matches('#mobile_menu_trigger'):
      burger.classList.toggle('open');
      break;
    default:
      break;
  }
});

// Mega Menu
const dropdownContainers = document.querySelectorAll('.title_container');
dropdownContainers.forEach(dropdown => {
  dropdown.addEventListener('click', (e) => {
    switch (true) {
      case e.target.matches('.mobile_arrow') && !dropdown.parentNode.classList.contains('show-sub'):
        dropdown.parentNode.classList.toggle('show-sub');
        dropdown.querySelector('.mobile_arrow .material-icons').innerHTML = 'remove';
        break;

      case e.target.matches('.mobile_arrow') && dropdown.parentNode.classList.contains('show-sub'):
        dropdown.parentNode.classList.toggle('show-sub');
        dropdown.querySelector('.mobile_arrow .material-icons').innerHTML = 'add';
    
      default:
        break;
    }
  });
});

// Toggling Mobile Menu
document.addEventListener('click', (e) => {
  switch (true) {
    case e.target.matches('#mobile_menu_trigger'):
      mobMenu.classList.toggle('visible');
      mobMenu.classList.remove('search_visible');
      mobMenuTrigger.classList.toggle('active');
      break;
    case e.target.matches('.header__overlay'):
      mobMenu.classList.remove('visible');
      mobMenuTrigger.classList.remove('active');
      break;
    case mobMenu.contains(e.target):
      break;
    default:
      //mobMenu.classList.remove('visible');
      //mobMenuTrigger.classList.remove('active');
      break;
  }
});


// Gallery Slider
let info;
var slideCurrentElement = document.querySelector('#gallery-count-current');
var slideCountElement = document.querySelector('#gallery-count-max');
let currentIndex;
let indexCount;
const initiateGallerySlider = () => {
  if ( document.getElementById('gallery_main') ) {
    var galleryImageSlider = tns({
      "container": '#gallery_main',
      "controlsContainer": '#gallery_controls',
      "navContainer": '#gallery_thumbs',
      "navAsThumbnails": true,
      "autoplay": false,
      "loop": false,
      "items": 1,
    });
  
    var galleryThumbSlider = tns({
      "loop": false,
      "container": '#gallery_thumbs',
      "items": 4,
      "mouseDrag": true,
      "nav": false,
      "controls": false,
      "gutter": 7.5,
      "responsive": {
        768: {
          "items": 6,
          "gutter": 15
        },
        992: {
          "items": 9
        }
      }
    });
  
    var prev = document.querySelector('#gallery_controls .prev');
    var next = document.querySelector('#gallery_controls .next');
  
    prev.addEventListener( 'click', () => {
      galleryThumbSlider.goTo('prev');
    });
    next.addEventListener( 'click', () => {
      galleryThumbSlider.goTo('next');
    });

    function determineSliderIndex() {
      info = galleryImageSlider.getInfo();
      currentIndex = info.displayIndex;
      indexCount = info.pages;
      slideCurrentElement.innerHTML = currentIndex;
      slideCountElement.innerHTML = indexCount;
    }
    determineSliderIndex();

    galleryImageSlider.events.on('indexChanged', determineSliderIndex);
  }
}

function initiateGalleryLightbox() {
  var lightbox = new SimpleLightbox('.gallery__main a', { 
    showCounter: false,
   });
}

document.addEventListener('DOMContentLoaded', () => {
  initiateGallerySlider();
  initiateGalleryLightbox();
});